import React from 'react';

import { Router, Switch, Route } from '../navigation/index';
import { LandingPage } from '../screens/LandingPage';
import { OldLandingPage } from '../screens/OldLandingPage';
import { UnsubscribeScreen } from '../screens/Unsubscribe';
import { NewLandingPage } from '../screens/NewLandingPage';
import { LoginScreen } from '../screens/LoginScreen';

export function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginScreen} />
        {/* <Route exact path="/newlp" component={LandingPage} /> */}
        {/* <Route exact path="/unsubscribe/:email" component={UnsubscribeScreen} /> */}
        {/* <Route exact path="/newnewlp" component={NewLandingPage} /> */}
      </Switch>
    </Router>
  );
}
