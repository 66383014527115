import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';

const env = process.env.NODE_ENV;

let uri;
if (env === 'development') {
  uri = 'http://192.168.0.4:4001/graphql';
} else if (env === 'production') {
  uri = 'https://hmerp-server.herokuapp.com/graphql';
} else {
  uri = 'http://192.168.0.4:4001/graphql';
}
// const uri = process.env.SERVER_URI;
const cache = new InMemoryCache();

const SERVER_LINK_OPTIONS = {
  uri,
  credentials: 'include',
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const httpLink = new HttpLink(SERVER_LINK_OPTIONS);

const link = authLink.concat(httpLink);

export const client = new ApolloClient({
  link,
  cache,
});
