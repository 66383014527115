import React, { useRef } from 'react';
import {
  TouchableOpacity,
  TouchableOpacityProps,
  ViewProps,
  Text,
  View,
  ActivityIndicator,
} from 'react-native';

import { Reforma1918Negra, blackCherry } from '../../libs/constants';

export interface PrimaryButtonProps extends TouchableOpacityProps {
  backgroundColor?: string;
  borderOnly?: boolean;
  children: string | React.ReactNode;
  contentContainerStyle?: ViewProps['style'];
  disabled?: boolean;
  foregroundColor?: string;
  hoverBackgroundColor?: string;
  hoverForegroundColor?: string;
  loading?: boolean;
  // loadingIndicatorStyle?: SpringAnimatedActivityIndicatorProps['style'];
  onPress: TouchableOpacityProps['onPress'];
  size?: number | null;
}

export const defaultButtonSize = 40;

export const PrimaryButton: React.FC<PrimaryButtonProps> = React.memo(
  (props: PrimaryButtonProps) => {
    const {
      backgroundColor,
      borderOnly,
      children,
      contentContainerStyle,
      disabled,
      foregroundColor,
      hoverBackgroundColor,
      hoverForegroundColor,
      loading,
      // loadingIndicatorStyle,
      size: _size,
      style,
      ...otherProps
    } = props;

    const size =
      typeof _size === 'number' || _size === null
        ? _size || undefined
        : defaultButtonSize;

    const touchableRef = useRef(null);

    return (
      <TouchableOpacity
        ref={touchableRef}
        disabled={disabled}
        {...otherProps}
        style={[
          {
            backgroundColor: backgroundColor ? backgroundColor : 'transparent',
          },
          style,
        ]}
      >
        <View
          style={[
            {
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: size,
              paddingHorizontal: 5,
              backgroundColor: backgroundColor,
              borderWidth: 0,
              borderRadius: 2,
            },
            contentContainerStyle,
          ]}
        >
          {loading ? (
            <ActivityIndicator size="small" />
          ) : typeof children === 'string' ? (
            <Text
              style={{
                lineHeight: 14,
                fontSize: 14,
                color: blackCherry,
                fontFamily: Reforma1918Negra,
              }}
            >
              {children}
            </Text>
          ) : (
            children
          )}
        </View>
      </TouchableOpacity>
    );
  }
);
