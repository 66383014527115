import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
};

export type CustomBaseEntity = {
   __typename?: 'CustomBaseEntity',
  id: Scalars['ID'],
  shortLink?: Maybe<Scalars['String']>,
  createdDate?: Maybe<Scalars['DateTime']>,
  updatedDate?: Maybe<Scalars['DateTime']>,
  version?: Maybe<Scalars['Float']>,
};

export type Customer = {
   __typename?: 'Customer',
  id: Scalars['ID'],
  shortLink?: Maybe<Scalars['String']>,
  createdDate?: Maybe<Scalars['DateTime']>,
  updatedDate?: Maybe<Scalars['DateTime']>,
  version?: Maybe<Scalars['Float']>,
  email: Scalars['String'],
  subscribeToNewsletter: Scalars['Boolean'],
};


export type Error = {
   __typename?: 'Error',
  path: Scalars['String'],
  message: Scalars['String'],
};

export type Mutation = {
   __typename?: 'Mutation',
  subscribe: StandardResponse,
  unsubscribe: StandardResponse,
};


export type MutationSubscribeArgs = {
  email: Scalars['String']
};


export type MutationUnsubscribeArgs = {
  email: Scalars['String']
};

export type Query = {
   __typename?: 'Query',
  me: Customer,
};


export type QueryMeArgs = {
  id: Scalars['String']
};

export type StandardResponse = {
   __typename?: 'StandardResponse',
  errors?: Maybe<Array<Error>>,
  success: Scalars['Boolean'],
};

export type SubscribeNewsletterMutationVariables = {
  email: Scalars['String']
};


export type SubscribeNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { subscribe: (
    { __typename?: 'StandardResponse' }
    & Pick<StandardResponse, 'success'>
    & { errors: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type UnsubscribeNewsletterMutationVariables = {
  email: Scalars['String']
};


export type UnsubscribeNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribe: (
    { __typename?: 'StandardResponse' }
    & Pick<StandardResponse, 'success'>
    & { errors: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);


export const SubscribeNewsletterDocument = gql`
    mutation SubscribeNewsletter($email: String!) {
  subscribe(email: $email) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type SubscribeNewsletterMutationFn = ApolloReactCommon.MutationFunction<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>;
export type SubscribeNewsletterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>, 'mutation'>;

    export const SubscribeNewsletterComponent = (props: SubscribeNewsletterComponentProps) => (
      <ApolloReactComponents.Mutation<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables> mutation={SubscribeNewsletterDocument} {...props} />
    );
    
export type SubscribeNewsletterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables> & TChildProps;
export function withSubscribeNewsletter<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubscribeNewsletterMutation,
  SubscribeNewsletterMutationVariables,
  SubscribeNewsletterProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables, SubscribeNewsletterProps<TChildProps>>(SubscribeNewsletterDocument, {
      alias: 'subscribeNewsletter',
      ...operationOptions
    });
};

/**
 * __useSubscribeNewsletterMutation__
 *
 * To run a mutation, you first call `useSubscribeNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeNewsletterMutation, { data, loading, error }] = useSubscribeNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubscribeNewsletterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>) {
        return ApolloReactHooks.useMutation<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>(SubscribeNewsletterDocument, baseOptions);
      }
export type SubscribeNewsletterMutationHookResult = ReturnType<typeof useSubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationResult = ApolloReactCommon.MutationResult<SubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationOptions = ApolloReactCommon.BaseMutationOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>;
export const UnsubscribeNewsletterDocument = gql`
    mutation UnsubscribeNewsletter($email: String!) {
  unsubscribe(email: $email) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type UnsubscribeNewsletterMutationFn = ApolloReactCommon.MutationFunction<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>;
export type UnsubscribeNewsletterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>, 'mutation'>;

    export const UnsubscribeNewsletterComponent = (props: UnsubscribeNewsletterComponentProps) => (
      <ApolloReactComponents.Mutation<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables> mutation={UnsubscribeNewsletterDocument} {...props} />
    );
    
export type UnsubscribeNewsletterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables> & TChildProps;
export function withUnsubscribeNewsletter<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnsubscribeNewsletterMutation,
  UnsubscribeNewsletterMutationVariables,
  UnsubscribeNewsletterProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables, UnsubscribeNewsletterProps<TChildProps>>(UnsubscribeNewsletterDocument, {
      alias: 'unsubscribeNewsletter',
      ...operationOptions
    });
};

/**
 * __useUnsubscribeNewsletterMutation__
 *
 * To run a mutation, you first call `useUnsubscribeNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeNewsletterMutation, { data, loading, error }] = useUnsubscribeNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUnsubscribeNewsletterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>) {
        return ApolloReactHooks.useMutation<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>(UnsubscribeNewsletterDocument, baseOptions);
      }
export type UnsubscribeNewsletterMutationHookResult = ReturnType<typeof useUnsubscribeNewsletterMutation>;
export type UnsubscribeNewsletterMutationResult = ApolloReactCommon.MutationResult<UnsubscribeNewsletterMutation>;
export type UnsubscribeNewsletterMutationOptions = ApolloReactCommon.BaseMutationOptions<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>;