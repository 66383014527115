import React, { useState } from 'react';
import { Formik } from 'formik';
import { View, TextInput, StyleSheet, Text } from 'react-native';
import { subscribe } from 'graphql';

import {
  GrandSlangItalic,
  emerald,
  Reforma1969NegraItalica,
  oatmeal,
  cajunShrimp,
  Reforma1969Gris,
  LowdragNarrow,
  blackCherry,
  mint,
} from '../../libs/constants';
import { PrimaryButton } from '../common/PrimaryButton';
import { SubscribeNewsletterDocument } from '../ApolloComponents';
import { useMutation } from '@apollo/react-hooks';

interface Props {}

interface FormValues {
  email: string;
}

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  subscribeForm: {
    marginVertical: 10,
    marginHorizontal: 0,
    paddingHorizontal: 15,
    width: '100%',
    flexGrow: 1,
    maxWidth: 500,
    flexDirection: 'column',
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emailInput: {
    minWidth: '50%',
    backgroundColor: '#fff',
    paddingLeft: 5,
    fontFamily: LowdragNarrow,
    textTransform: 'uppercase',
    height: 40,
    flexGrow: 1,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: blackCherry,
    marginVertical: 10,
  },
  loginButton: {
    width: 235,
    // flex: 1,
    backgroundColor: mint,
    // fontFamily: Reforma1969Gris,
    // textAlignVertical: true,
    height: 40,
    shadowColor: blackCherry,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 50,
  },
});

export const LoginForm: React.FC<Props> = () => {
  const [subscribe, { data }] = useMutation(SubscribeNewsletterDocument);
  const [disabled, setDisabled] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayDup, setDisplayDup] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  return (
    <View style={styles.container}>
      <Formik<FormValues>
        initialValues={{ email: '' }}
        onSubmit={async (values, { resetForm }) => {
          const result = await subscribe({
            variables: {
              email: values.email,
            },
          });
          if (result.data.subscribe.success === true) {
            setHideForm(true);
            resetForm({ values: { email: '' } });
          }
          if (
            result.data.subscribe.success === false &&
            result.data.subscribe.errors[0].message === 'Already in the squad'
          ) {
            setDisplayDup(true);
            console.log('squad');
          }
          // console.log('result', result);
          // console.log('data', data);
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, values }) => (
          <View style={{ width: '100%' }}>
            {hideForm ? (
              <View style={{ width: '100%' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: GrandSlangItalic,
                    textTransform: 'uppercase',
                    color: emerald,
                    fontSize: 36,
                  }}
                >
                  Thanks for signing up!
                </Text>
              </View>
            ) : (
              <View style={{ alignItems: 'center' }}>
                <View style={styles.subscribeForm}>
                  <TextInput
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    style={styles.emailInput}
                    placeholder="you@email.com"
                  />
                  <TextInput
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    style={styles.emailInput}
                    placeholder="password"
                  />

                  <PrimaryButton
                    onPress={() => {
                      console.log('value', values.email);
                      setDisplayDup(false);
                      setDisplayError(false);
                      if (values.email === null || values.email === '') {
                        setDisplayError(true);
                        return;
                      }
                      handleSubmit();
                    }}
                    children="Log In"
                    backgroundColor={oatmeal}
                    style={[
                      styles.loginButton,
                      { marginVertical: 0, borderRadius: 2 },
                    ]}
                  />
                </View>
              </View>
            )}
            {displayError ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: cajunShrimp,
                    fontFamily: Reforma1969Gris,
                  }}
                >
                  ***please fill out ze form***
                </Text>
              </View>
            ) : null}
            {displayDup ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: cajunShrimp,
                    fontFamily: Reforma1969Gris,
                  }}
                >
                  ***you're already in the squad***
                </Text>
              </View>
            ) : null}
          </View>
        )}
      </Formik>
    </View>
  );
};
