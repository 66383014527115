import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import { Routes } from './navigation/Routes';
import { client } from './libs/apollo';
import { DimensionsProvider } from './context/DimensionsContext';

export function App() {
  return (
    <DimensionsProvider>
      <ApolloProvider client={client}>
        <>
          <Routes />
        </>
      </ApolloProvider>
    </DimensionsProvider>
  );
}
