import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {
  GrandSlangItalic,
  LowdragNarrow,
  blackCherry,
  flax,
} from '../libs/constants';
import { LoginForm } from '../components/forms/LoginForm';

interface Props {}

export const LoginScreen: React.FC<Props> = () => {
  const styles = StyleSheet.create({
    containerDark: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: blackCherry,
    },
    containerLight: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: flax,
    },
    secret: {
      fontFamily: GrandSlangItalic,
      fontSize: 96,
      textTransform: 'uppercase',
      color: flax,
    },
    hm: {
      fontFamily: LowdragNarrow,
      fontSize: 56,
      textTransform: 'uppercase',
      color: blackCherry,
    },
  });
  return (
    <View>
      <View style={styles.containerDark}>
        <Text style={styles.secret}>Top Secret</Text>
      </View>
      <View style={styles.containerLight}>
        <Text style={styles.hm}>Happy Medium</Text>
        <LoginForm />
      </View>
    </View>
  );
};
